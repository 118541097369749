import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + '/api/payment/check-slip';

// ฟังก์ชันสำหรับการอัปโหลดสลิปและตรวจสอบกับ API
export const checkSlip = async (slipImage, productId, price ,orderId) => {
  const formData = new FormData();
  formData.append('slipImageUrl', slipImage);
  formData.append('productId', productId);
  formData.append('amount', price);
  formData.append('orderId', orderId);

  try {
    const response = await axios.post(API_URL, formData, {
      headers: {'Content-Type': 'multipart/form-data', // แจ้งว่าเป็นการอัปโหลดไฟล์
      },
    });
    return response.data; // ส่งข้อมูลผลลัพธ์กลับ เช่น code_card
  } catch (error) {
    console.error('Error checking slip:', error);
    throw error; // ส่ง error กลับไปเพื่อให้ frontend จัดการต่อ
  }
};
