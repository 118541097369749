import { fetchAllMembers, fetchMemberById, updateMember, deleteMemberById, addMember as addMemberApi } from '../../controllers/UserController';

// Fetch all members
export const fetchMembers = () => async (dispatch) => {
  dispatch({ type: 'FETCH_MEMBER_REQUEST' });
  try {
    const members = await fetchAllMembers();
    dispatch({ type: 'FETCH_MEMBER_SUCCESS', payload: members });
  } catch (error) {
    dispatch({ type: 'FETCH_MEMBER_FAILURE', payload: error.message });
  }
};

// Fetch a single member by ID
export const fetchMemberByIdAction = (id) => async (dispatch) => {
  dispatch({ type: 'FETCH_MEMBER_REQUEST' });
  try {
    const member = await fetchMemberById(id);
    dispatch({ type: 'FETCH_MEMBER_SUCCESS', payload: [member] });
  } catch (error) {
    dispatch({ type: 'FETCH_MEMBER_FAILURE', payload: error.message });
  }
};

// Update a member by ID
export const updateMemberAction = (id, updatedMemberData) => async (dispatch) => {
  dispatch({ type: 'UPDATE_MEMBER_REQUEST' });
  try {
    const updatedMember = await updateMember(id, updatedMemberData);
    dispatch({ type: 'UPDATE_MEMBER_SUCCESS', payload: updatedMember });
  } catch (error) {
    dispatch({ type: 'UPDATE_MEMBER_FAILURE', payload: error.message });
  }
};

// Delete a member
export const deleteMemberAction = (id) => async (dispatch) => {
  dispatch({ type: 'DELETE_MEMBER_REQUEST' });
  try {
    await deleteMemberById(id);
    dispatch({ type: 'DELETE_MEMBER_SUCCESS', payload: id });
    dispatch(fetchMembers());  // Refresh the list after deletion
  } catch (error) {
    dispatch({ type: 'DELETE_MEMBER_FAILURE', payload: error.message });
  }
};

// Set LINE User ID
export const setLineUserId = (lineUserId) => ({
  type: 'SET_LINE_USER_ID',
  payload: lineUserId,
});

// Set User ID
export const setUserId = (userId) => ({
  type: 'SET_USER_ID',
  payload: userId,
});

// Set LINE Profile
export const setLineProfile = (profile) => ({
  type: 'SET_LINE_PROFILE',
  payload: profile,
});

// Add a new member action
export const addMemberAction = (newMemberData) => async (dispatch) => {
  dispatch({ type: 'ADD_MEMBER_REQUEST' });
  try {
    const newMember = await addMemberApi(newMemberData);
    dispatch({ type: 'ADD_MEMBER_SUCCESS', payload: newMember });
    dispatch(fetchMembers());  // Fetch the updated member list after adding
  } catch (error) {
    dispatch({ type: 'ADD_MEMBER_FAILURE', payload: error.message });
  }
};