import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Button, CircularProgress, Alert } from '@mui/material';
import { GetOrderDetailsForUser } from '../../controllers/orderController'; // ดึงข้อมูล order

const CardReceivedPage = () => {
  const { orderId } = useParams(); // ดึง orderId จาก URL
  const [orderDetails, setOrderDetails] = useState(null); // เก็บข้อมูล order
  const [loading, setLoading] = useState(true); // สถานะการโหลด
  const [error, setError] = useState(null); // สถานะข้อผิดพลาด
  const apiUrl = process.env.REACT_APP_API_URL; // URL สำหรับรูปภาพ

  // ดึงข้อมูล order เมื่อ component ถูก mount
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const data = await GetOrderDetailsForUser(orderId); // เรียก API
        setOrderDetails(data); // เก็บข้อมูล order ใน state
        //console.log(data);
      } catch (err) {
        setError('เกิดข้อผิดพลาดในการโหลดข้อมูลบัตร: ' + err.message);
      } finally {
        setLoading(false); // หยุดสถานะโหลด
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  if (loading) return <CircularProgress style={{ marginTop: '20px' }} />; // แสดงสถานะโหลด
  if (error) return <Alert severity="error">{error}</Alert>; // แสดงข้อความข้อผิดพลาด

  return (
    <div style={{ textAlign: 'center', padding: '10px' }}>
      <Typography variant="h4" gutterBottom>บัตรของคุณ</Typography>

      <img
        src={`${apiUrl}${orderDetails.imageUrl}`} // รูปภาพบัตร
        alt={orderDetails.productName}
        style={{
          width: '100%',
          maxWidth: '500px',
          height: 'auto',
          borderRadius: '8px',
          marginBottom: '20px'
        }}
      />

      <Typography variant="h5" gutterBottom>{orderDetails.productName}</Typography> {/* ชื่อบัตร */}
      <Typography variant="body1" gutterBottom>จำนวน: {orderDetails.quantity}</Typography> {/* จำนวน */}

      {/* แสดง Code1 และ Code2 */}
      <Typography variant="h6" color="textSecondary" style={{ marginTop: '20px' }}>
        รหัส 1: {orderDetails.code1}
      </Typography>
      <Typography variant="h6" color="textSecondary" style={{ marginTop: '10px' }}>
        รหัส 2: {orderDetails.code2}
      </Typography>
    </div>
  );
};

export default CardReceivedPage;
