import React, { useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux'; // นำเข้า useSelector
import { registerUser } from '../../controllers/UserController';
import { useNavigate } from 'react-router-dom';

function RegisterPage() {
  const [formData, setFormData] = useState({ name: '', email: '', password: '' });
  
  // ใช้ useSelector เพื่อดึง lineUserId จาก Redux store
  const lineUserId = useSelector((state) => state.members?.lineUserId);

  // ใช้ useNavigate เพื่อใช้ในการเปลี่ยนหน้า
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      // รวม lineUserId เข้ากับ formData
      const dataToSend = { ...formData, lineUserId };

      // ส่งข้อมูล form ไปยัง API เพื่อทำการลงทะเบียน
      await registerUser(dataToSend);
      alert('Registration successful!'); // แสดงข้อความเมื่อสำเร็จ
      navigate('/'); // ใช้ navigate เพื่อเปลี่ยนหน้าไปยังหน้าแรก
    } catch (error) {
      alert('Error in registration'); // แสดงข้อความเมื่อเกิดข้อผิดพลาด
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Register</Typography>
      <TextField
        name="name"
        label="Name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        name="email"
        label="Email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
{
  /*
        <TextField
        name="password"
        label="Password"
        type="password"
        value={formData.password}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
  */
}
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Register
      </Button>
    </Container>
  );
}

export default RegisterPage;
