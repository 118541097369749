import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // นำเข้า useDispatch จาก Redux
import RegisterPage from '../pages/line-lift/RegisterPage';
import CardPage from '../pages/line-lift/CardPage';
import PaymentPage from '../pages/line-lift/PaymentPage';
import CardDetailsPage from '../pages/line-lift/CardDetailsPage';
import OrderHistoryPage from '../pages/line-lift/OrderHistoryPage';
import CardReceivedPage from '../pages/line-lift/CardReceivedPage';
import liff from '@line/liff';
import { checkUserRegistration } from '../controllers/UserController';
import { setLineUserId, setLineProfile, setUserId } from '../stores/actions/memberActions'; // นำเข้า Action

function LineLiftRoutes() {
  const [isLineApp, setIsLineApp] = useState(null);
  const [isRegistered, setIsRegistered] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch(); // เรียกใช้ useDispatch เพื่อส่งค่าไปยัง Redux

  useEffect(() => {
    console.log("Running in", process.env.NODE_ENV, "mode");
    // ตรวจสอบสภาพแวดล้อม
    if (process.env.REACT_APP_TEST_LINE === '1') {
      const lineUserId = 'U5368427b8451eb52c58e6e00'; // จำลอง LINE User ID
      setIsLineApp(true);
      dispatch(setLineUserId(lineUserId)); // เก็บ Line User ID ลงใน Redux
      
      checkUserRegistration(lineUserId)
        .then(result => {
          console.log("User registration status:", result);
          dispatch(setUserId(result?.user?.userID)); // เก็บ User ID ลงใน Redux
          setIsRegistered(result.isRegistered);
          if (!result.isRegistered) {
            navigate('/register'); // ใช้ navigate แทนการ return
          }
        })
        .catch(err => {
          console.error('Error checking registration:', err);
        });
    } else {
      setIsLineApp(true);
      // ถ้าอยู่ใน production หรืออื่น ๆ ให้ใช้ LIFF SDK
      liff.init({ liffId: '1657803441-XMvlLZj3' })
        .then(() => {
          if (liff.isInClient()) {
            console.log("Running inside LINE App");
            liff.getProfile()
              .then(profile => {
                const lineUserId = profile.userId;
                dispatch(setLineUserId(lineUserId)); // เก็บ Line User ID ลงใน Redux
                console.log("Line User ID:", lineUserId);

                // ตรวจสอบการลงทะเบียนผ่าน API
                checkUserRegistration(lineUserId)
                  .then(result => {
                    console.log("User ID:", result?.user?.userID);
                    console.log("User registration status:", result);
                    setIsRegistered(result?.isRegistered);
                    
                    dispatch(setUserId(result?.user?.userID)); // เก็บ User ID ลงใน Redux
                    liff.getProfile()
                      .then(profile => {
                        console.log("Profile:", profile);
                        const { userId, displayName, pictureUrl, statusMessage } = profile;
                        dispatch(setLineUserId(userId));
                        dispatch(setLineProfile(profile)); // เก็บชื่อและรูปลงใน Redux
                      })

                    if (!result.isRegistered) {
                      navigate('/register');
                    }
                  })
                  .catch(err => {
                    console.error("Error checking registration:", err);
                  });
              })
              .catch(err => {
                console.error("Error getting profile:", err);
              });
          } else {
            console.log("Not running inside LINE App");
            setIsLineApp(false);
          }
        })
        .catch(error => {
          console.error('LIFF init failed', error);
          setIsLineApp(false);
        });
    }
  }, [navigate, dispatch]);

  // ตรวจสอบสถานะ loading
  if (isLineApp === null || isRegistered === null) {
    return <div>Loading...</div>;
  }

  // ถ้าไม่ใช่ LINE App
  if (!isLineApp) {
    navigate('/not-allowed'); // ใช้ navigate แทน return
  }

  // ถ้าลงทะเบียนแล้ว, ให้แสดงเส้นทางปกติ
  return (
    <Routes>
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/" element={<CardPage />} />
      <Route path="/card" element={<CardPage />} />
      <Route path="/card/:id/payment/:orderId" element={<PaymentPage />} />
      <Route path="/card/:id" element={<CardDetailsPage />} />
      <Route path="/user/:userId/orders" element={<OrderHistoryPage />} />
      <Route path="/card/:id/CardReceived/:orderId" element={<CardReceivedPage />} />
      
    </Routes>
  );
}

export default LineLiftRoutes;
