// src/pages/NotAllowedPage.js

import React from 'react';

function NotAllowedPage() {
  return (
    <div style={styles.container}>
      <h1>Access Denied</h1>
      <p>This feature is only available through the LINE app.</p>
    </div>
  );
}

// ตัวอย่างการใช้ inline styles (คุณสามารถปรับแต่ง CSS ได้เองตามต้องการ)
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
    padding: '20px',
  }
};

export default NotAllowedPage;
