// src/controllers/AdminController.js
import axios from 'axios';

// ดึง URL ของ API มาจาก .env
const API_URL = process.env.REACT_APP_API_URL;

// ฟังก์ชันสำหรับ Login ของ Admin
const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_URL}/api/admin/login`, {
      username,
      password
    });
    
    // ส่ง token กลับมาเมื่อ Login สำเร็จ
    return response.data.token;
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};

export default {
  login,
};
