import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMembers, deleteMemberAction } from '../../stores/actions/memberActions';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button, Dialog, DialogActions, DialogTitle, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const MemberListPage = () => {
  const dispatch = useDispatch();
  const members = useSelector(state => state.members.memberList);
  const loading = useSelector(state => state.members.loading);
  const error = useSelector(state => state.members.error);

  const [open, setOpen] = useState(false);  // State to manage dialog visibility
  const [selectedUserId, setSelectedUserId] = useState(null);  // Track which member is being deleted

  useEffect(() => {
    dispatch(fetchMembers());
  }, []);

  const handleOpenDialog = (userId) => {
    setSelectedUserId(userId);  // Set the selected user ID to be deleted
    setOpen(true);  // Open the dialog
  };

  const handleCloseDialog = () => {
    setOpen(false);  // Close the dialog
    setSelectedUserId(null);  // Reset selected user ID
  };

  const handleDelete = () => {
    dispatch(deleteMemberAction(selectedUserId));
    handleCloseDialog();  // Close the dialog after deletion
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Box display="flex" justifyContent="center" mt={4}>  {/* Center the table with some top margin */}
      <TableContainer component={Paper} style={{ width: '80%', maxWidth: '1200px' }}>  {/* Limit table width */}
        <Button component={Link} to="/admin/members/add" variant="contained" color="primary">
          Add Member
        </Button>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Member ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((member) => (
              <TableRow key={member.userID}>
                <TableCell>{member.userID}</TableCell>
                <TableCell>{member.name}</TableCell>
                <TableCell>{member.email}</TableCell>
                <TableCell>
                  {/* Edit Button: Filled style with Edit Icon */}
                  <Button
                    component={Link}
                    to={`/admin/members/${member.userID}`}
                    variant="contained" // Solid style
                    color="primary"     // Blue color (default primary)
                    startIcon={<EditIcon />} // Icon before the text
                  >
                    Edit
                  </Button>

                  {/* Delete Button: Filled style with Delete Icon */}
                  <Button
                    variant="contained"  // Solid style
                    color="error"        // Red color for delete actions
                    startIcon={<DeleteIcon />}  // Icon before the text
                    onClick={() => handleOpenDialog(member.userID)}
                    style={{ marginLeft: '8px' }}  // Add space between buttons
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* Confirmation Dialog */}
        <Dialog open={open} onClose={handleCloseDialog}>
          <DialogTitle>
            Are you sure you want to delete this member?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </Box>
  );
};

export default MemberListPage;
