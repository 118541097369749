import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Header() {
  const members = useSelector(state => state.members);
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Starbucks Card
        </Typography>
        {
          // <Button color="inherit" component={Link} to="/admin">Admin</Button>
        }
        <Button color="inherit" component={Link} to="/card">Home</Button>
        {
          members.userId ? (
            <Button color="inherit" component={Link} to={`/user/${members.userId}/orders`}>History</Button>
          ) : null
        }
        
      </Toolbar>
    </AppBar>
  );
}

export default Header;
