import axios from 'axios';

// URL ของ API ควรตั้งค่าในไฟล์ .env
const API_URL = process.env.REACT_APP_API_URL + '/api';

// ลงทะเบียนผู้ใช้ใหม่
export const registerUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/user/register`, userData, {
      headers: {
        'Content-Type': 'application/json', // ระบุประเภทข้อมูลที่ส่ง
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

// ตรวจสอบการลงทะเบียนผู้ใช้ผ่าน LINE User ID
export const checkUserRegistration = async (lineUserId) => {
  try {
    const response = await axios.get(`${API_URL}/user/check`, {
      params: { lineUserId }, // ส่ง lineUserId เป็น query parameter
    });
    return response.data; // assuming API returns `isRegistered`
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return { isRegistered: false };
    } else {
      console.error("Error checking registration:", error);
      throw error;
    }
  }
};

// Fetch all members
export const fetchAllMembers = async () => {
  try {
    const response = await axios.get(`${API_URL}/user`);
    return response.data;
  } catch (error) {
    console.error('Error fetching members:', error);
    throw error;
  }
};

// Fetch a member by ID
export const fetchMemberById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/user/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching member with ID ${id}:`, error);
    throw error;
  }
};

// Update a member by ID
export const updateMember = async (id, updatedMemberData) => {
  try {
    const response = await axios.put(`${API_URL}/user/${id}`, updatedMemberData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating member with ID ${id}:`, error);
    throw error;
  }
};

// Delete a member by ID
export const deleteMemberById = async (id) => {
  try {
    await axios.delete(`${API_URL}/user/${id}`);
  } catch (error) {
    console.error(`Error deleting member with ID ${id}:`, error);
    throw error;
  }
};

// Add a new member
export const addMember = async (newMemberData) => {
  try {
    const response = await axios.post(`${API_URL}/user`, newMemberData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding member:', error);
    throw error;
  }
};
