// src/pages/admin/LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import LoginForm from '../../components/LoginForm';
import AdminController from '../../controllers/AdminController';

const LoginPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLoginSubmit = async (credentials) => {
    try {
      // เรียกฟังก์ชัน login ใน AdminController
      const token = await AdminController.login(credentials.username, credentials.password);
      
      // เก็บ token ใน localStorage
      localStorage.setItem('token', token);

      // Redirect ไปหน้า Admin เมื่อ login สำเร็จ
      navigate('/admin');
    } catch (error) {
      setErrorMessage('Login failed. Please check your credentials.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#e0e0e0',
      }}
    >
      <LoginForm onSubmit={handleLoginSubmit} />
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </Box>
  );
};

export default LoginPage;
