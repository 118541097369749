import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// ฟังก์ชันสำหรับอัปโหลดไฟล์ไปยัง backend
export const uploadFile = async (file) => {
  if (!file) return null;

  const token = localStorage.getItem('token'); // ดึง JWT token จาก localStorage
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(API_URL + '/api/FileUpload/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}` // ส่ง JWT token ใน header
      },
    });

    // ส่งกลับ URL ของไฟล์ที่ถูกอัปโหลด
    return response.data.fileUrl;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};
