// src/routes/AdminRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminPage from '../pages/admin/AdminPage';
import ProductListPage from '../pages/admin/ProductListPage';
import MemberListPage from '../pages/admin/MemberListPage';
import MemberDetailPage from '../pages/admin/MemberDetailPage';
import ProductDetailPage from '../pages/admin/ProductDetailPage';
import LoginPage from '../pages/admin/LoginPage';
import PrivateRoute from '../components/PrivateRoute'; // Import PrivateRoute

function AdminRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />

      {/* หน้าเหล่านี้ต้องตรวจสอบว่าเป็น Admin ที่ล็อกอินแล้ว */}
      <Route 
        path="" 
        element={
          <PrivateRoute>
            <AdminPage />
          </PrivateRoute>
        }
      />
      <Route 
        path="/products" 
        element={
          <PrivateRoute>
            <ProductListPage />
          </PrivateRoute>
        } 
      />
      <Route 
        path="/products/:productId" 
        element={
          <PrivateRoute>
            <ProductDetailPage />
          </PrivateRoute>
        } 
      />
            <Route 
        path="/products/new" 
        element={
          <PrivateRoute>
            <ProductDetailPage />
          </PrivateRoute>
        } 
      />
      <Route 
        path="/members" 
        element={
          <PrivateRoute>
            <MemberListPage />
          </PrivateRoute>
        } 
      />
      <Route 
        path="/members/add" 
        element={
          <PrivateRoute>
            <MemberDetailPage />
          </PrivateRoute>
        } 
      />
      <Route 
        path="/members/:id" 
        element={
          <PrivateRoute>
            <MemberDetailPage />
          </PrivateRoute>
        } 
      />
    </Routes>
  );
}

export default AdminRoutes;
