// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

// ฟังก์ชันตรวจสอบว่าผู้ใช้ล็อกอินหรือไม่
const isAuthenticated = () => {
  const token = localStorage.getItem('token'); // ดึง JWT Token จาก localStorage
  return !!token; // ถ้ามี Token แสดงว่าล็อกอินแล้ว
};

const PrivateRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/admin/login" />;
};

export default PrivateRoute;
