// src/stores/reducers/index.js
import { combineReducers } from 'redux';
import memberReducer from './memberReducer';

const rootReducer = combineReducers({
  members: memberReducer,
  // reducers อื่นๆ ถ้ามี
});

export default rootReducer;
