import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { GetProductByIdForUser } from '../../controllers/productController';
import { createOrder } from '../../controllers/orderController';
import { useSelector } from 'react-redux';
import { Typography, Button, CircularProgress, Alert } from '@mui/material';

const CardDetailsPage = () => {
    const { id } = useParams(); // รับ productId จาก URL
    const [product, setProduct] = useState(null); // ข้อมูลสินค้า
    const [loading, setLoading] = useState(true); // สถานะการโหลด
    const [error, setError] = useState(null); // สถานะข้อผิดพลาด

    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const members = useSelector(state => state.members); // ข้อมูลสมาชิกจาก Redux Store

    // ดึงข้อมูลสินค้าเมื่อ component ถูก mount
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const productData = await GetProductByIdForUser(id);
                setProduct(productData.product); // เก็บข้อมูลสินค้า
            } catch (err) {
                setError('ไม่สามารถโหลดข้อมูลสินค้าได้: ' + err.message);
            } finally {
                setLoading(false); // หยุดแสดงสถานะการโหลด
            }
        };

        fetchProduct();
    }, [id]);

    // ฟังก์ชันจัดการการชำระเงิน
    const handlePayment = async () => {
        const userId = members?.userId;
        const orderItems = [{
            productID: product.productID,
            quantity: 1,
            price: product.discount || product.price, // ใช้ราคาหลังส่วนลด (ถ้ามี)
        }];

        try {
            const result = await createOrder(userId, orderItems); // สร้างคำสั่งซื้อ
            navigate(`/card/${id}/payment/${result.orderId}`); // นำทางไปที่หน้าชำระเงินสำเร็จ
        } catch (err) {
            alert('เกิดข้อผิดพลาดในการสั่งซื้อ: ' + err.message);
        }
    };

    if (loading) return <CircularProgress style={{ marginTop: '20px' }} />; // แสดงสถานะโหลด
    if (error) return <Alert severity="error">{error}</Alert>; // แสดงข้อความข้อผิดพลาด

    return (
        <div style={{ textAlign: 'center', padding: '10px' }}>
            <Typography variant="h4" gutterBottom>{product.productName}</Typography>

            <img
                src={`${apiUrl}${product.imageUrl}`}
                alt={product.productName}
                style={{
                    width: '100%',
                    maxWidth: '500px',
                    height: 'auto',
                    borderRadius: '8px',
                    marginBottom: '10px'
                }}
            />

            <Typography variant="body1" paragraph>{product.description}</Typography>

            {product.discount > 0 ? (
                <>
                    <Typography variant="body1" color="text.primary">
                        ราคา: <s>{product.price} บาท</s>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        ลด {((product.price - product.discount) / product.price) * 100}% <b>เหลือ: {product.discount} บาท</b>
                    </Typography>
                </>
            ) : (
                <Typography variant="body1" color="text.primary">
                    ราคา: {product.price} บาท
                </Typography>
            )}

            {/* แสดงจำนวน Stock ที่เหลืออยู่ */}
            <Typography variant="h6" style={{ marginTop: '20px' }}>
                สินค้าคงเหลือ: {product.stock} ชิ้น
            </Typography>

            {product.stock === 0 && (
                <Alert severity="warning" style={{ marginTop: '10px' }}>
                    ขณะนี้สินค้าหมด
                </Alert>
            )}

            <Button
                onClick={handlePayment}
                variant="contained"
                color="primary"
                style={{ marginTop: '20px' }}
                disabled={product.stock === 0} // ปิดการใช้งานปุ่มถ้าสินค้าหมด
            >
                ชำระเงิน
            </Button>
        </div>
    );
};

export default CardDetailsPage;
