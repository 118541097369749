import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';

function AdminPage() {
  return (
    <Container>
      <Typography variant="h4">Admin Dashboard</Typography>
      <Button component={Link} to="/admin/products" variant="contained">Manage Products</Button>
      <Button component={Link} to="/admin/members" variant="contained">Manage Members</Button>
    </Container>
  );
}

export default AdminPage;
