// controllers/orderController.js
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

export const createOrder = async (userId, orderItems) => {
    
    try {
        const response = await axios.post(`${apiUrl}/api/order/create`, {
            userId,
            orderItems
        });
        return response.data; // ส่งข้อมูลคำสั่งซื้อสำเร็จกลับมา
    } catch (error) {
        throw new Error(`Error creating order: ${error.message}`);
    }
};


export const getUserOrderHistory = async (userId) => {
    try {
        const response = await axios.get(`${apiUrl}/api/order/user-orders/${userId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching order history:', error);
        throw error;
    }
};

export const GetOrderDetailsForUser = async (orderId) => {
    try {
        const response = await axios.get(`${apiUrl}/api/order/${orderId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching order history:', error);
        throw error;
    }
  };
