import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMemberByIdAction, updateMemberAction, addMemberAction } from '../../stores/actions/memberActions';
import { TextField, Button, CircularProgress, Select, MenuItem, FormControl, InputLabel, Dialog, DialogTitle, DialogActions } from '@mui/material';

const MemberDetailPage = () => {
  const { id } = useParams();  // id will be undefined if adding a new member
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const member = useSelector(state => state.members.memberList[0]);  // The current member to be edited
  const loading = useSelector(state => state.members.loading);
  const error = useSelector(state => state.members.error);  // Optional error handling if you have it

  const [formData, setFormData] = useState({
    username: '',
    passwordHash: '',
    lineUserId: '',
    email: '',
    name: '',
    role: 'User',  // Default role is 'User'
  });

  const [openDialog, setOpenDialog] = useState(false);  // State to manage the dialog

  // Fetch member data if editing (id exists)
  useEffect(() => {
    if (id) {
      dispatch(fetchMemberByIdAction(id));
    }
  }, [dispatch, id]);

  // Pre-fill form data when editing
  useEffect(() => {
    if (id && member) {
      setFormData({
        username: member.username || '',
        passwordHash: '', // Password is typically not pre-filled for security reasons
        lineUserId: member.lineUserId || '',
        email: member.email || '',
        name: member.name || '',
        role: member.role || 'User', // Default to 'User' if no role
      });
    } else if (id && !member && !loading) {
      // If we're in edit mode, but the member is not found, show the dialog
      setOpenDialog(true);
    }
  }, [member, id, loading]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (id) {
      // If there's an ID, it's an update
      dispatch(updateMemberAction(id, formData));
    } else {
      // If no ID, add a new member
      dispatch(addMemberAction(formData));
    }
    navigate('/admin/members');  // Redirect back to members list
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate('/admin/members');  // Navigate back to members list
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <TextField
          name="username"
          label="Username"
          value={formData.username}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          name="passwordHash"
          label="Password"
          type="password"
          value={formData.passwordHash || ''}
          onChange={handleChange}
          fullWidth
          required={!id}  // Make password required only when adding a new member
          margin="normal"
        />
        <TextField
          name="lineUserId"
          label="LINE User ID"
          value={formData.lineUserId}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          name="name"
          label="Name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          required
          margin="normal"
        />

        {/* Role Dropdown */}
        <FormControl fullWidth required margin="normal">
          <InputLabel id="role-label">Role</InputLabel>
          <Select
            labelId="role-label"
            name="role"
            value={formData.role}
            onChange={handleChange}
            label="Role"
          >
            <MenuItem value="User">User</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
          </Select>
        </FormControl>

        <Button type="submit" variant="contained" color="primary" fullWidth>
          {id ? 'Save Changes' : 'Add Member'}  {/* Change button text based on whether it's an edit or add */}
        </Button>
        <Button onClick={() => navigate('/admin/members')} color="secondary" fullWidth style={{ marginTop: '10px' }}>
          Cancel
        </Button>
      </form>

      {/* Dialog for member not found */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Member Not Found</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MemberDetailPage;
