import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'; // นำเข้า useSelector เพื่อดึงข้อมูลจาก Redux
import { getAllProducts } from '../../controllers/productController';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar'; // นำเข้า Avatar เพื่อแสดงรูปโปรไฟล์
import Box from '@mui/material/Box'; // ใช้ Box เพื่อจัด layout
import { Link } from 'react-router-dom';

const CardPage = () => {
    const [products, setProducts] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    // ดึงข้อมูล lineUserName และ lineUserProfileImage จาก Redux
    const lineUserName = useSelector(state => state.members.lineUserName);
    const lineUserProfileImage = useSelector(state => state.members.lineUserProfileImage);
    const members = useSelector(state => state.members);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsData = await getAllProducts();
                setProducts(productsData);
                console.log('members', members);
            } catch (error) {
                alert('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    // การจัดสไตล์ให้กับ Box และ Grid เพื่อตำแหน่งที่อยู่กลาง
    const profileBoxStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '24px',
    };

    return (
        <Container>
            {/* จัดตำแหน่งรูปโปรไฟล์และชื่อผู้ใช้ให้อยู่กึ่งกลาง */}
            <Box sx={profileBoxStyle}>
                <Avatar
                    src={members?.lineProfile?.pictureUrl}
                    alt={lineUserName}
                    sx={{ width: 80, height: 80, mb: 2 }} // เพิ่มขนาด Avatar และระยะห่าง
                />
                <Typography variant="h4" align="center">
                    {members?.lineProfile?.displayName}
                </Typography>
            </Box>

            {/* จัดตำแหน่ง Card ให้อยู่ใน Grid */}
            <Grid container spacing={4} justifyContent="center">
                {products.map(product => (
                    <Grid item key={product.productID} xs={12} sm={12} md={12} lg={12}> {/* ปรับให้เหมาะกับหน้าจอทุกขนาด */}
                        <Link to={`/card/${product.productID}`} style={{ textDecoration: 'none' }}>
                            <Card>
                                <Box display="flex" flexDirection="row">
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 200 }} // กำหนดความกว้างของรูป
                                        image={`${apiUrl}${product.imageUrl}`}
                                        alt={product.productName}
                                    />
                                    <Box display="flex" flexDirection="column" justifyContent="space-between" p={2}>
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {product.productName}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {product.description}
                                            </Typography>
                                            {product.discount > 0 ? (
                                                <>
                                                    <Typography variant="body1" color="text.primary">
                                                        ราคา: <s>{product.price} บาท</s>
                                                    </Typography>
                                                    <Typography variant="body1" color="text.secondary">
                                                        ลด {(((product.price - product.discount) / product.price) * 100).toFixed(2)}% <b>เหลือ: {product.discount} บาท</b>
                                                    </Typography>
                                                </>
                                            ) : (
                                                <Typography variant="body1" color="text.primary">
                                                    ราคา: {product.price} บาท
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </Box>
                                </Box>
                            </Card>

                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default CardPage;
