import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography, TextField, CircularProgress } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import generatePayload from 'promptpay-qr';
import { useParams } from 'react-router-dom';
import { GetProductByIdForUser } from '../../controllers/productController';
import { checkSlip } from '../../controllers/PaymentController';
import liff from '@line/liff';
import * as fileController from '../../controllers/FileController';
import { useNavigate } from 'react-router-dom';

const PaymentPage = () => {
  const { id, orderId } = useParams();
  const [product, setProduct] = useState(null);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [slipImage, setSlipImage] = useState(null);
  const [isSlipValid, setIsSlipValid] = useState(null);
  const [codeCard, setCodeCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const qrCodeRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const productData = await GetProductByIdForUser(id);
        setProduct(productData.product);
      } catch (error) {
        setErrorMsg('เกิดข้อผิดพลาดในการโหลดข้อมูลสินค้า');
      }
      setLoading(false);
    };

    if (id) {
      fetchProduct();
    }
  }, [id]);

  useEffect(() => {
    if (product) {
      const generateQRCode = () => {
        const phoneNumber = '0962989162';
        const qrData = generatePayload(phoneNumber, { amount: product.discount || product.price });
        setQrCodeData(qrData);
      };

      generateQRCode();
    }
  }, [product]);

  const handleSlipUpload = (e) => {
    setSlipImage(e.target.files[0]);
  };

  const handleCheckSlip = async () => {
    if (!slipImage) {
      alert('กรุณาอัปโหลดสลิปก่อนตรวจสอบ');
      return;
    }

    setLoading(true);
    try {
      const response = await checkSlip(slipImage, id, product.discount || product.price, orderId);

      if(response.success) {
        //`/card/${order.productID}/CardReceived/${order.orderID}`
        navigate(`/card/${id}/CardReceived/${orderId}`);
      } else {
        setIsSlipValid(response.success);
        setCodeCard(response.code1 + ' ' + response.code2);
        setErrorMsg(response.message);
      }


    } catch (error) {
      setIsSlipValid(false);
      setErrorMsg(error?.response?.data?.message || 'เกิดข้อผิดพลาดในการตรวจสอบสลิป');
    }
    setLoading(false);
  };

  const handleDownloadQRCode = async () => {
    try {
      const canvas = qrCodeRef.current.querySelector('canvas');
      const pngUrl = canvas.toDataURL('image/png');
  
      // Convert base64 to Blob
      const byteString = atob(pngUrl.split(',')[1]);
      const mimeString = 'image/png';
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
  
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
  
      const blob = new Blob([ab], { type: mimeString });
  
      // Create a File object from the Blob
      const file = new File([blob], 'qrcode.png', { type: 'image/png' });
  
      // Upload file using fileController.uploadFile
      const fileUrl = await fileController.uploadFile(file);
  
      // Initialize LIFF and open the URL
      await liff.init({ liffId: '1657803441-XMvlLZj3' });
  
      liff.openWindow({
        url: process.env.REACT_APP_API_URL + fileUrl,
        external: true,
      });
    } catch (error) {
      console.error('Error downloading or uploading QR code:', error);
    }
  };
  

  /*
    const handleDownloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector('canvas');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qrcode.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  */



  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  };

  return (
    <div style={containerStyle}>
      <Typography variant="h4" gutterBottom>ชำระเงิน</Typography>
      {loading && <CircularProgress />}
      {product ? (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h6">{product.productName}</Typography>

          {/* การแสดงผลราคาพร้อมส่วนลด (ถ้ามี) */}
          {product.discount > 0 ? (
            <>
              <Typography variant="body1" color="textSecondary">
                ราคาเดิม: <s>{product.price} บาท</s>
              </Typography>
              <Typography variant="body1" color="textPrimary">
                ราคาหลังหักส่วนลด: <b>{product.discount} บาท</b>
              </Typography>
            </>
          ) : (
            <Typography variant="body1" color="textPrimary">
              ราคา: {product.price} บาท
            </Typography>
          )}

          {qrCodeData ? (
            <div ref={qrCodeRef}>
              <QRCodeCanvas value={qrCodeData} size={256} />
              <Typography variant="h5" style={{ marginTop: '10px' }}>
                กรุณาชำระเงินโดยการสแกน QR Code
              </Typography>

              <Button
                variant="outlined"
                onClick={handleDownloadQRCode}
                style={{ marginTop: '10px' }}
              >
                ดาวน์โหลด QR Code
              </Button>

              <TextField
                type="file"
                onChange={handleSlipUpload}
                inputProps={{ accept: 'image/*' }}
                margin="normal"
              />
              <Button
                variant="contained"
                onClick={handleCheckSlip}
                style={{ marginTop: '10px' }}
              >
                ตรวจสอบสลิป
              </Button>

              {isSlipValid !== null && (
                <Typography variant="h6" style={{ marginTop: '10px' }}>
                  {isSlipValid
                    ? `สลิปถูกต้อง, Code Card: ${codeCard}`
                    : 'สลิปไม่ถูกต้อง ' + errorMsg}
                </Typography>
              )}
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      ) : (
        <Typography variant="h6">{errorMsg || 'ไม่พบข้อมูลสินค้า'}</Typography>
      )}
    </div>
  );
};

export default PaymentPage;
