import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + '/api/product';

// ดึงข้อมูลสินค้าทั้งหมด
export const getAllProducts = async () => {
  const token = localStorage.getItem('token'); // ดึง JWT token
  try {
    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Bearer ${token}`, // ส่ง JWT token ใน header
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

// ดึงข้อมูลสินค้าตาม ID (ฟังก์ชันนี้ขาดไป)
export const getProductById = async (productId) => {
  const token = localStorage.getItem('token'); // ดึง JWT token
  try {
    const response = await axios.get(`${API_URL}/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`, // ส่ง JWT token ใน header
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching product with ID ${productId}:`, error);
    throw error;
  }
};

export const GetProductByIdForUser = async (productId) => {
  const token = localStorage.getItem('token'); // ดึง JWT token
  try {
    const response = await axios.get(`${API_URL}/${productId}/user`, {
      headers: {
        Authorization: `Bearer ${token}`, // ส่ง JWT token ใน header
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching product with ID ${productId}:`, error);
    throw error;
  }
};

// เพิ่มสินค้าใหม่
export const addProduct = async (product) => {
  const token = localStorage.getItem('token'); // ดึง JWT token
  try {
    const response = await axios.post(API_URL, product, {
      headers: {
        Authorization: `Bearer ${token}`, // ส่ง JWT token ใน header
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding product:', error);
    throw error;
  }
};

// แก้ไขสินค้า
export const updateProduct = async (productId, product) => {
  const token = localStorage.getItem('token'); // ดึง JWT token
  try {
    const response = await axios.put(`${API_URL}/${productId}`, product, {
      headers: {
        Authorization: `Bearer ${token}`, // ส่ง JWT token ใน header
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating product with ID ${productId}:`, error);
    throw error;
  }
};

// ลบสินค้า
export const deleteProduct = async (productId) => {
  const token = localStorage.getItem('token'); // ดึง JWT token
  try {
    const response = await axios.delete(`${API_URL}/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`, // ส่ง JWT token ใน header
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error deleting product with ID ${productId}:`, error);
    throw error;
  }
};
