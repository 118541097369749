import React, { useState, useEffect } from 'react';
import { getUserOrderHistory } from '../../controllers/orderController';
import { useParams, Link } from 'react-router-dom'; // นำเข้า Link เพื่อสร้างลิงก์

const OrderHistoryPage = () => {
    const { userId } = useParams();
    const [orderHistory, setOrderHistory] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOrderHistory = async () => {
            try {
                const history = await getUserOrderHistory(userId);
                setOrderHistory(history);
            } catch (error) {
                console.error('Error fetching order history:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrderHistory();
    }, [userId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{ padding: '10px' }}>
            <h1>ประวัติการสั่งซื้อ</h1>
            {orderHistory.length === 0 ? (
                <p>ไม่มีประวัติการสั่งซื้อ</p>
            ) : (
                orderHistory.map((order, index) => (
                    <div key={order.orderID + '-' + index} style={{ borderBottom: '1px solid #ccc', marginBottom: '10px' }}>
                        <h2>คำสั่งซื้อ #{order.orderID}</h2>
                        <p>วันที่: {new Date(order.orderDate).toLocaleString()}</p>
                        <p>ยอดรวม: {order.totalAmount} บาท</p>

                        {/* แสดงสถานะการชำระเงินเป็นลิงก์หากสำเร็จ */}
                        {order.isPaymentSuccessful ? (
                            <Link to={`/card/${order.productID}/CardReceived/${order.orderID}`}>
                                <p style={{ color: 'green', textDecoration: 'underline', cursor: 'pointer' }}>
                                    ชำระเงินสำเร็จ (ดูรายละเอียดบัตร)
                                </p>
                            </Link>
                        ) : (
                            <p style={{ color: 'red' }}>ชำระเงินไม่สำเร็จ: {order.paymentErrorMessage}</p>
                        )}

                        {/* ตรวจสอบว่ามี codeCard หรือไม่ */}
                        {order.codeCard && <p>Code Card: {order.codeCard}</p>}
                    </div>
                ))
            )}
        </div>
    );
};

export default OrderHistoryPage;
