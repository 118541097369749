const initialState = {
  memberList: [],
  loading: false,
  error: null,
  lineUserId: null,  // Stores LINE User ID
  userId: null,      // Stores the system's User ID
  lineUserName: null,  // Stores the user's LINE name
  lineUserProfileImage: null,  // Stores the user's LINE profile image
  lineProfile: null,  // Stores the complete LINE profile data
  currentMember: null,  // Stores the current member being edited
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_MEMBER_REQUEST':
      return { ...state, loading: true, error: null };
    case 'ADD_MEMBER_SUCCESS':
      return { ...state, loading: false, memberList: [...state.memberList, action.payload] }; // Add the new member to the list
    case 'ADD_MEMBER_FAILURE':
      return { ...state, loading: false, error: action.payload };
      
    // Fetch Member
    case 'FETCH_MEMBER_REQUEST':
      return { ...state, loading: true, error: null };
    case 'FETCH_MEMBER_SUCCESS':
      return { ...state, loading: false, memberList: action.payload };
    case 'FETCH_MEMBER_FAILURE':
      return { ...state, loading: false, error: action.payload };

    // Set LINE User Data
    case 'SET_LINE_USER_ID':
      return { ...state, lineUserId: action.payload };
    case 'SET_USER_ID':
      return { ...state, userId: action.payload };
    case 'SET_LINE_PROFILE':  // Set LINE profile data
      return { 
        ...state, 
        lineProfile: action.payload,
        lineUserName: action.payload.name,  // Assuming payload contains 'name'
        lineUserProfileImage: action.payload.picture // Assuming payload contains 'picture'
      };

    // Delete Member
    case 'DELETE_MEMBER_REQUEST':
      return { ...state, loading: true, error: null };
    case 'DELETE_MEMBER_SUCCESS':
      return { 
        ...state, 
        loading: false, 
        memberList: state.memberList.filter(member => member.id !== action.payload) 
      };
    case 'DELETE_MEMBER_FAILURE':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default memberReducer;
